import React from 'react'
import { SkeletonActions } from '../SkeletonActions'
import { SkeletonFilters } from '../SkeletonFilters'
import { SkeletonTable } from '../SkeletonTable'
import { SkeletonWindows } from '../SkeletonWindows'
import './SkeletonBody.sass'

interface SkeletonBodyProps {
  mode: 'windows' | 'table'
  columnsCount: number
  withFilters: boolean
  actionMode: 'all' | 'none' | 'pagination'
}

export const SkeletonBody: React.FC<SkeletonBodyProps> = ({
  mode,
  withFilters,
  actionMode,
  columnsCount,
}) => {
  return (
    <div className='skeleton-body'>
      {withFilters && <SkeletonFilters className='skeleton-body__filters' />}
      {mode === 'table' && <SkeletonTable columnCount={columnsCount} />}
      {mode === 'windows' && <SkeletonWindows />}
      {actionMode !== 'none' && (
        <SkeletonActions
          withButton={actionMode === 'all'}
          className='skeleton-body__actions'
        />
      )}
    </div>
  )
}
