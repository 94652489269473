import { Route, Routes, Navigate, Outlet } from 'react-router'
import { AuthPage } from './pages/AuthPage'
import { Navbar } from './modules/Navbar'
import { GeneralPage } from './pages/GeneralPage'
import { ModerationPage } from './pages/ModerationPage'
import { NotFoundPage } from './pages/NotFoundPage'
import { ModerationHistoryPage } from './pages/ModerationHistoryPage'
import { ForbiddenPage } from './pages/ForbiddenPage'
import { useSelector } from 'react-redux'
import {
  FORBIDDEN_PATH,
  MAIN_PATH,
  MODERATION_HISTORY_PATH,
  MODERATION_PATH,
  NOT_FOUND_PATH,
  SIGNIN_PATH,
} from './constants/globalConstants'
import { ScrollToTop } from './components/ScrollToTop'
import { GetParameterPopup } from './components/GetParameterPopup'
import { RequireModerator } from './components/RequireModerator'
import { RejectDialog } from './components/RejectDialog'
import { ImgFullScreen } from './components/ImgFullScreen'
import { RootState } from './reducers'

export const AppRoutes: React.FC = () => {
  const isAuth = useSelector((state: RootState) => state.auth.isAuth)
  return isAuth ? (
    <div className='admin-page'>
      <ScrollToTop />
      <Navbar />
      <div className='admin-page-body'>
        <Routes>
          <Route element={<RequireModerator />}>
            <Route path={MAIN_PATH} element={<GeneralPage />} />
            <Route path={MODERATION_PATH} element={<Outlet />}>
              <Route index element={<ModerationPage />} />
              <Route path={':id'} element={<Outlet />}>
                <Route path={`deny`} element={<RejectDialog />} />
                <Route path={'view'} element={<ImgFullScreen />} />
              </Route>
            </Route>
            <Route
              path={MODERATION_HISTORY_PATH}
              element={<ModerationHistoryPage />}
            />
          </Route>
          <Route path={FORBIDDEN_PATH} element={<ForbiddenPage />} />
          <Route path={NOT_FOUND_PATH} element={<NotFoundPage />} />
        </Routes>
        <GetParameterPopup />
      </div>
    </div>
  ) : (
    <Routes>
      <Route path={SIGNIN_PATH} element={<AuthPage />} />
      <Route path='*' element={<Navigate to={SIGNIN_PATH} />} />
    </Routes>
  )
}
