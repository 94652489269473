import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import React from 'react'
import SelectNew from 'react-select'
import { Button } from '../../../../components/Button'
import { EmptyModeration } from '../../../../components/EmptyModeration'
import { MaterialDetailInfo } from '../../../../components/MaterialDetailInfo'
import { MaterialDetailItem } from '../../../../components/MaterialDetailItem'
import { MaterialImg } from '../../../../components/MaterialImg'
import { StatusItem } from '../../../../components/StatusItem'
import { getProtectedPathFromHash } from '../../../../utils/utils'
import {
  useCategories,
  useModerateAcceptMaterial,
  useModerationMaterial,
  useRotateLeft,
  useRotateRight,
} from './ModerationModule-hooks'
import { OptionType, TagsType } from '../../../../utils/types'
import {
  ModeartionMaterialType,
  ModerateAcceptAPIType,
} from './ModerationModule-types'
import { SkeletonModeration } from '../../../../components/SkeletonModeration'
import { isObjectEmpty } from '../../../../utils/check'
import { InputTag } from '../../../../components/InputTag'
import { Textarea } from '../../../../components/Textarea'
import { RotationBlock } from '../../../../components/RotationBlock'
import { Checkbox } from '../../../../components/Checkbox'
import { useNavigate } from 'react-router'
import { useGetUrlParam } from '../../../../hooks/useGetUrlParam'
import { useQueryClient } from 'react-query'
import { MODERATION_MATERIALS_CACHE_PATH } from '../../../../constants/globalConstants'

export const ModerationModule: React.FC = () => {
  const materialIdFromURL = useGetUrlParam('moderationId')
  const client = useQueryClient()
  const navigate = useNavigate()
  const [protectedHash, setProtectedHash] = React.useState<string>('')
  const [category, setCategory] = React.useState<OptionType>({} as OptionType)
  const [tags, setTags] = React.useState<TagsType>([])
  const [description, setDescription] = React.useState('')
  const [exclusiveRights, setExclusiveRights] = React.useState(false)

  const {
    data: moderationMaterial,
    isLoading,
    isError,
  } = useModerationMaterial(materialIdFromURL) as {
    data: ModeartionMaterialType
    isLoading: boolean
    isError: boolean
  }

  const { data: categories, isLoading: isCategoriesLoading } =
    useCategories() as {
      data: OptionType[]
      isLoading: boolean
    }

  const { mutateAsync: moderateAccept, isLoading: loadingAccept } =
    useModerateAcceptMaterial(materialIdFromURL) as {
      mutateAsync: (values: ModerateAcceptAPIType) => Promise<any>
      isLoading: boolean
    }
  const { mutateAsync: rotateLeft, isLoading: isRotatingLeft } = useRotateLeft(
    setProtectedHash
  ) as {
    mutateAsync: (id: number) => Promise<any>
    isLoading: boolean
  }
  const { mutateAsync: rotateRight, isLoading: isRotatingRight } =
    useRotateRight(setProtectedHash) as {
      mutateAsync: (id: number) => Promise<any>
      isLoading: boolean
    }

  const handleSubmit = () => {
    const moderateObject = {
      id: moderationMaterial.id,
      categoryId: category.id,
      tags: tags,
      description: description ? description : undefined,
      exclusiveRights: exclusiveRights,
    }
    moderateAccept(moderateObject)
  }

  const handleModalClick = () => {
    navigate(`${moderationMaterial.id}/deny`)
  }

  const handlePhotoClick = () => {
    navigate(`${moderationMaterial.id}/view`)
  }

  React.useEffect(() => {
    if (moderationMaterial?.category?.id) {
      setCategory({
        id: moderationMaterial.category.id,
        label: moderationMaterial.category.category,
        value: `${moderationMaterial.category.id}`,
      })
    }
    if (moderationMaterial?.tags?.length) {
      setTags(moderationMaterial.tags)
    }
    if (moderationMaterial?.description) {
      setDescription(moderationMaterial.description)
    }
    if (moderationMaterial?.protectedHash) {
      setProtectedHash(moderationMaterial.protectedHash)
    }
    if (moderationMaterial && 'exclusiveRights' in moderationMaterial) {
      setExclusiveRights(moderationMaterial?.exclusiveRights)
    }
  }, [moderationMaterial])

  React.useEffect(() => {
    return () => {
      client.removeQueries(MODERATION_MATERIALS_CACHE_PATH)
    }
  }, [client])

  const handleCategoryChange = (option: any) => {
    setCategory(option)
  }
  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(e.target.value)
  }

  const handleTagsChange = (value: string) => {
    if (!value) {
      return
    }
    if (!tags.includes(value)) {
      const newTagsAraray = [...tags, value]
      setTags(newTagsAraray)
    }
  }
  const handleTagsCancel = (value: string) => {
    const tagIndex = tags.indexOf(value)
    const filteredTags = tags.filter((_, i) => i !== tagIndex)
    setTags(filteredTags)
  }
  const handleRightsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExclusiveRights(e.currentTarget.checked)
  }

  const imgPath = getProtectedPathFromHash(protectedHash)

  if (isLoading) {
    return <SkeletonModeration />
  }

  if (isError) {
    return <EmptyModeration />
  }

  const uploadDateValue = format(
    parseISO(moderationMaterial?.uploadDate),
    'dd.MM.yyyy',
    {
      locale: ru,
    }
  )
  const rejectionText = moderationMaterial?.rejection?.rejection
  const statusValue = moderationMaterial.status
  const authorValue = moderationMaterial?.contributor?.name
  const leftMaterialsValue = `${moderationMaterial?.materialsLeft}`

  return (
    <div className='material-details'>
      <div className='material-details-info'>
        <MaterialDetailInfo
          className='material-details-info__item'
          title='Загружено'
          value={uploadDateValue ?? 'неизвестно'}
        />
        {rejectionText && (
          <MaterialDetailInfo
            className='material-details-info__item'
            title='Причина отказа'
            value={rejectionText}
          />
        )}
        <StatusItem
          className='material-details-info__item'
          value={statusValue}
          title={'Статус: '}
        />
        <StatusItem
          className='material-details-info__item'
          value={leftMaterialsValue}
          title={'Еще на модерации: '}
        />
      </div>
      <div className='material-details-data'>
        <div className='material-details-data-properties'>
          <MaterialDetailItem title='Автор' data={authorValue} />
          <h2 className='material-details-data-properties__title'>Категория</h2>
          <SelectNew
            className='material-details-data-properties__control react-select-container'
            classNamePrefix='react-select'
            placeholder='Выберите категорию'
            value={isObjectEmpty(category) ? '' : category}
            onChange={handleCategoryChange}
            options={categories}
            isLoading={isCategoriesLoading}
            loadingMessage={() => 'Загрузка категорий...'}
          />
          <h2 className='material-details-data-properties__title'>Теги</h2>
          <InputTag
            className='material-details-data-properties__control'
            tags={tags}
            handleSubmit={handleTagsChange}
            handleCancel={handleTagsCancel}
          />
          <h2 className='material-details-data-properties__title'>Описание</h2>
          <Textarea
            rows={4}
            placeholder='Создайте подробное описание...'
            value={description}
            onChange={handleDescriptionChange}
            className='material-details-data-properties__control'
          />
          <h2 className='material-details-data-properties__title'>Права</h2>
          <Checkbox
            className={'material-upload-body-info__control'}
            title={'Передать исключительные права на фото'}
            checked={exclusiveRights}
            onChange={handleRightsChange}
          />
        </div>

        <div className='material-details-data-photo'>
          <MaterialImg
            srcPath={imgPath}
            className='material-details-data-photo__img'
            onClick={handlePhotoClick}
            contain
          />
          <RotationBlock
            rotateLeft={() => rotateLeft(moderationMaterial.id)}
            rotateRight={() => rotateRight(moderationMaterial.id)}
            isRotatingLeft={isRotatingLeft}
            isRotatingRight={isRotatingRight}
          />
          <Button
            className='material-details-data-photo__btn'
            disabled={!tags.length || !category.id}
            loading={loadingAccept}
            onClick={handleSubmit}
          >
            Опубликовать
          </Button>
          <Button
            className='material-details-data-photo__btn'
            type='danger'
            loading={loadingAccept}
            onClick={handleModalClick}
          >
            Отклонить
          </Button>
        </div>
      </div>
    </div>
  )
}
