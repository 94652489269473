import { MAIN_PATH, MODERATION_API_PATH } from '../../constants/globalConstants'

export const breadCrumbsData = [
  {
    id: 0,
    name: 'Кабинет администратора',
    path: MAIN_PATH,
  },
  {
    id: 1,
    name: 'Модерация',
    path: MODERATION_API_PATH,
  },
]
