export const materialStatusOptions = [
  {
    label: 'Все',
    value: undefined,
  },
  {
    label: 'Новые',
    value: 'new',
  },
  {
    label: 'Ожидают решения',
    value: 'waiting',
  },
  {
    label: 'Опубликованы',
    value: 'accept',
  },
  {
    label: 'Отклонены',
    value: 'deny',
  },
  // {
  //   label: 'Возвращены',
  //   value: 'rollback',
  // },
]
