import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import {
  BANK_PHOTO_PATH,
  ROLE_ADMIN,
  ROLE_CONSUMER,
  ROLE_CONTRIBUTOR,
  ROLE_MODERATOR,
} from '../constants/globalConstants'

export const getStatus = (status: string) => {
  if (status === 'active') return 'Активен'
  if (status === 'disabled') return 'Удален'
  if (status === 'locked') return 'Заблокирован'
}

export const getUserType = (type: string) => {
  if (type === ROLE_CONSUMER) return 'Потребитель'
  if (type === ROLE_CONTRIBUTOR) return 'Поставщик'
  if (type === ROLE_ADMIN) return 'Администратор'
  if (type === ROLE_MODERATOR) return 'Модератор'
  else return 'Неизвестно'
}

export const getMaterialStatus = (status: string) => {
  if (status === 'new') return 'Новый'
  if (status === 'waiting') return 'Ожидает решения'
  if (status === 'accept') return 'Опубликован'
  if (status === 'deny') return 'Отклонен'
  // if (status === 'rollback') return 'Возвращен на модерацию'
  else return status
}

export const getOrderStatus = (status: string) => {
  if (status === 'new') return 'Новая'
  if (status === 'waiting') return 'Модерация'
  if (status === 'accept') return 'Одобрено'
  if (status === 'deny') return 'Отказано'
  else return status
}

export const getRawPathFromHash = (hash: string) => {
  if (hash) {
    return `${BANK_PHOTO_PATH}/raw/${hash.slice(0, 2)}/${hash.slice(
      2,
      4
    )}/${hash}.jpg`
  } else {
    return ''
  }
}
export const getProtectedPathFromHash = (hash: string) => {
  if (hash) {
    return `${BANK_PHOTO_PATH}/protected/${hash.slice(0, 2)}/${hash.slice(
      2,
      4
    )}/${hash}.jpg`
  } else {
    return ''
  }
}

export const getNormalizeDate = (date: string) => {
  if (date) {
    return format(parseISO(date), 'dd.MM.yyyy', {
      locale: ru,
    })
  } else {
    return 'Неизвестно'
  }
}
