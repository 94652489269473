import React from 'react'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { breadcrumbs } from './GeneralPage-constants'
import { InfoModule } from './modules/InfoModule'

export const GeneralPage = () => {
  useBreadcrumbs(breadcrumbs)
  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule title={'Статистика модератора'} />
      </div>
      <div className='common-page-body'>
        <InfoModule />
      </div>
    </div>
  )
}
