import classNames from 'classnames'
import React from 'react'
import { useNavigate } from 'react-router'
import Close from '../../assets/img/close.svg'
import { UserImg } from '../../components/UserImg'
import { ModeratorSelfType } from '../../utils/types'
import { MenuModule } from '../MenuModule'
import { useFetchProfileInfo } from './ModeratorProfile-hooks'
import './ModeratorProfile.sass'

export const ModeratorProfile = ({
  isOpened = false,
}: {
  isOpened?: boolean
}) => {
  const navigate = useNavigate()
  const closeProfileWindow = () => {
    navigate(-1)
  }
  const divPrfx = 'moderator-profile'
  const divCls = classNames(divPrfx)

  const { data } = useFetchProfileInfo() as {
    data: ModeratorSelfType
  }

  return (
    <div className={divCls}>
      <div className='moderator-profile__close' onClick={closeProfileWindow}>
        <img src={Close} alt='Закрыть панель' />
      </div>
      <div className='moderator-profile-content'>
        <div className='moderator-profile-content-top'>
          <UserImg
            className='moderator-profile-content-top__photo'
            photo={''}
          />
          <span className='moderator-profile-content-top__login'>
            {data?.login}
          </span>
          <span className='moderator-profile-content-top__role'>Модератор</span>
        </div>
        <div className='moderator-profile-content-bottom'>
          <MenuModule
            alignCenter={true}
            className='moderator-profile-content-bottom__menu'
          />
        </div>
      </div>
    </div>
  )
}
