import { MAIN_PATH } from '../../constants/globalConstants'

export const breadcrumbs = [
  {
    id: 0,
    name: 'Кабинет модератора',
    path: MAIN_PATH,
  },
  {
    id: 1,
    name: 'Статистика модератора',
    path: MAIN_PATH,
  },
]
