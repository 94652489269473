import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { toast } from 'react-toastify'
import {
  BACKEND_URL,
  FORBIDDEN_PATH,
  NOT_FOUND_PATH,
  SIGNIN_PATH,
} from '../constants/globalConstants'
import { authDataResetAction } from '../pages/AuthPage/modules/AuthModule-actions'
import store from '../store'
import { isAuthenticated } from './auth'
import history from './history'
import { getToken, LSClear } from './storage'

export const instance: AxiosInstance = axios.create({
  withCredentials: false,
  baseURL: BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = getToken()
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error: AxiosError) => {
    Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // Некорректные данные для входа
          store.dispatch(authDataResetAction())
          if (isAuthenticated()) {
            LSClear()
            toast.info('Пожалуйста, войдите снова')
          }
          history.push(SIGNIN_PATH)
          break
        // return
        case 403:
          history.push(FORBIDDEN_PATH)
          break
        case 404:
          history.push(NOT_FOUND_PATH)
          break
        case 500:
          toast.error('Сервер вернул ошибку 500')
          break
        default:
          console.log('Other error')
          break
      }
    } else if (error.request) {
      console.log('Incorrect request')
    } else {
      console.log('something else in interceptor axios')
    }
    return Promise.reject(error.response.data?.detail[0]?.msg)
  }
)
