import { MATERIAL_API_PATH } from '../constants/globalConstants'
import { instance } from '../utils/coreAPI'

export const getMaterialDetailsById = async (id: string) => {
  try {
    const response = await instance.get(`${MATERIAL_API_PATH}/${id}`)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error?.response?.data.detail)
  }
}
