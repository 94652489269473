import classNames from 'classnames'
import React from 'react'
import { getMaterialStatus, getNormalizeDate } from '../../utils/utils'
import './ModerationHistoryWindow.sass'

interface ModerationHistoryWindowProps {
  className?: string
  materialId: number
  moderateEnd: string
  materialAuthor: string
  status: string
  onClick: () => void
}

export const ModerationHistoryWindow: React.FC<
  ModerationHistoryWindowProps
> = ({
  className,
  materialId,
  moderateEnd,
  materialAuthor,
  status,
  onClick,
}) => {
  const windowPrfx = 'moderation-history-window'
  const windowCls = classNames(windowPrfx, {
    [`${className}`]: className,
  })

  const statusPrfx = 'moderation-history-window__status'
  const statusCls = classNames(statusPrfx, {
    [`${statusPrfx}_accept`]: status === 'accept',
    [`${statusPrfx}_deny`]: status === 'deny',
  })

  const statusValue = getMaterialStatus(status)
  const materialIdValue = materialId ?? 'Неизвестно'
  const moderateEndValue = getNormalizeDate(moderateEnd)
  const materialAuthorValue = materialAuthor

  return (
    <div className={windowCls} onClick={onClick}>
      <div className={statusCls}>{statusValue}</div>
      <div className={`${windowPrfx}__material`}>
        ID материала:{' '}
        <span className={`${windowPrfx}__material-value`}>
          {materialIdValue}
        </span>
      </div>
      <div className={`${windowPrfx}__author`}>
        Автор:{' '}
        <span className={`${windowPrfx}__author-value`}>
          {materialAuthorValue}
        </span>
      </div>
      <div className={`${windowPrfx}__date`}>
        {moderateEndValue === 'Неизвестно' ? 'Еще не закончена' : moderateEnd}
      </div>
    </div>
  )
}
