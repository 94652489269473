export const GET_PARAMS = {
  popup: 'popup',
  notificationId: 'notification-id',
}

export const GET_ENUMS = {
  popup: {
    signIn: 'sign-in',
    profileInfo: 'profileInfo',
  },
}
