export const sortOptions = [
  {
    label: 'Сначала старые',
    value: undefined,
  },
  {
    label: 'Сначала новые',
    value: 'desc.add',
  },
  {
    label: 'Сначала популярные',
    value: 'desc.pop',
  },
  {
    label: 'Сначала непопулярные',
    value: 'asc.pop',
  },
]
