export const BACKEND_URL: string =
  process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:8888/'
export const BANK_PHOTO_PATH =
  process.env.REACT_APP_DATA_URL ?? 'https://bankphototest.s3.amazonaws.com'

export const ROLE_MODERATOR = 'moder'
export const ROLE_ADMIN = 'admin'
export const ROLE_CONTRIBUTOR = 'contr'
export const ROLE_CONSUMER = 'consm'

export const STATISTIC_API_PATH = '/statistics'

export const GEO_API_PATH = '/geo/prompt'

export const FORBIDDEN_PATH = '/forbidden'

export const AUTH_API_PATH = '/auth/login'
export const LOGOUT_API_PATH = 'auth/logout'

export const STATISTICS_API_PATH = '/statistics'

export const SELF_PATH = `/self`
export const SELF_CACHE_PATH = 'self'
export const SELF_STATISTICS = `${STATISTICS_API_PATH}`

export const MAIN_PATH = '/main'
export const MAIN_CACHE_PATH = 'main'

export const MODERATOR_PATH = '/moderator'
export const MODERATORS_PATH = '/moderators'

export const CATEGORY_API_PATH = '/category'
export const CATEGORY_ALL_API_PATH = '/category/list'
export const CATEGORIES_PATH = '/categories'
export const CATEGORIES_CACHE_PATH = 'categories'

export const REJECTION_API_PATH = '/rejection'
export const REJECTION_ALL_API_PATH = '/rejection/list'
export const REJECTIONS_PATH = '/rejections'
export const REJECTIONS_CACHE_PATH = 'rejections'

export const MATERIAL_API_PATH = '/material'
export const MATERIALS_PATH = '/materials'
export const MATERIALS_CACHE_PATH = 'materials'
export const MATERIALS_STATISTIC_API_PATH = `${STATISTIC_API_PATH}/material`

export const MODERATION_HISTORY_API_PATH = `${MATERIAL_API_PATH}/verification/history`
export const MODERATION_HISTORY_PATH = '/history/moderation'
export const MODERATION_HISTORY_CACHE_PATH = 'moderationHistory'

export const MODERATION_PATH = '/moderation'
export const MODERATION_API_PATH = `${MATERIAL_API_PATH}/waiting/rand`
export const MODERATION_MATERIALS_CACHE_PATH = 'moderationMaterial'

export const SIGNIN_PATH = '/signin'
export const NOT_FOUND_PATH = '/404'
