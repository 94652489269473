import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import {
  CATEGORIES_CACHE_PATH,
  MODERATION_MATERIALS_CACHE_PATH,
  MODERATION_PATH,
} from '../../../../constants/globalConstants'
import {
  getCategories,
  getModerationMaterial,
  materialRotateLeftCall,
  materialRotateRightCall,
  moderateAcceptMaterial,
} from './ModerationModule-api'
import { ModerateAcceptAPIType } from './ModerationModule-types'

export const useModerationMaterial = (materialId: string | null) => {
  const query = useQuery(
    [MODERATION_MATERIALS_CACHE_PATH],
    async () => await getModerationMaterial(materialId),
    {
      retry: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    }
  )
  return query
}

export const useModerateAcceptMaterial = (materialId: string | null) => {
  const navigate = useNavigate()
  const client = useQueryClient()
  const mutation = useMutation(
    (values: ModerateAcceptAPIType) => moderateAcceptMaterial(values),
    {
      onSuccess: () => {
        navigate(`${MODERATION_PATH}`)
        client.removeQueries(MODERATION_MATERIALS_CACHE_PATH)
        client.refetchQueries(MODERATION_MATERIALS_CACHE_PATH)
      },
      onError: (error: any) => {
        if (error?.response?.status === 400) {
          navigate('..')
          toast.error(error ?? 'Недостаточно прав на выполнение операции')
        } else {
          toast.error(error ?? 'Не удалось завершить операцию')
        }
      },
    }
  )
  return mutation
}

export const useRotateRight = (setNewProtectedHash: (hash: string) => void) => {
  const mutation = useMutation(
    (materialId: number) => materialRotateRightCall(materialId),
    {
      onSuccess: async (data) => {
        setNewProtectedHash(data.protectedHash)
      },
      onError: async (error: any) => {
        toast.error(error ?? 'Не удалось осуществить поворот')
      },
    }
  )
  return mutation
}
export const useRotateLeft = (setNewProtectedHash: (hash: string) => void) => {
  const mutation = useMutation(
    (materialId: number) => materialRotateLeftCall(materialId),
    {
      onSuccess: async (data) => {
        setNewProtectedHash(data.protectedHash)
      },
      onError: async (error: any) => {
        toast.error(error ?? 'Не удалось осуществить поворот')
      },
    }
  )
  return mutation
}

export const useCategories = () => {
  const query = useQuery([CATEGORIES_CACHE_PATH], getCategories, {
    refetchOnMount: true,
    staleTime: 600000,
    onSuccess: (data) => {},
    onError: (error: any) => {
      toast.error(error ?? 'Не удалось получить список категорий')
    },
  })
  return query
}
