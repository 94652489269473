import classNames from 'classnames'
import React from 'react'
import queryString from 'query-string'
import Select from 'react-select'
import { getMaterialStatusOption } from './FilterMaterialStatus-utils'
import { materialStatusOptions } from './FilterMaterialStatus-constants'
import { NavigateFunction } from 'react-router'
import {
  MATERIAL_STATUS_URL_VALUE,
  PAGE_URL_VALUE,
} from '../../constants/filterURLNames'

interface FilterMaterialStatusProps {
  className?: string
  navigate: NavigateFunction
  params: { [k: string]: string }
}

export const FilterMaterialStatus: React.FC<FilterMaterialStatusProps> = ({
  className,
  navigate,
  params,
}) => {
  const filterPrfx = 'filter-param-container'
  const filterCls = classNames(filterPrfx, {
    [`${className}`]: className,
  })
  const status = params[MATERIAL_STATUS_URL_VALUE]

  const [statusFilter, setStatusFilter] = React.useState(
    getMaterialStatusOption(status)
  )

  React.useEffect(() => {
    setStatusFilter(getMaterialStatusOption(status))
  }, [status])

  const handleMaterialStatusChange = (option: any) => {
    setStatusFilter(getMaterialStatusOption(option.value))
    params[MATERIAL_STATUS_URL_VALUE] = option.value
    params[PAGE_URL_VALUE] = '1'
    navigate({ search: queryString.stringify(params) })
  }

  return (
    <div className={filterCls}>
      <label className={`${filterPrfx}__label`} htmlFor='materialStatus'>
        Cтатус
      </label>
      <Select
        id='materialStatus'
        className='react-select-container'
        classNamePrefix='react-select'
        getOptionValue={(option) => `${option.label}`}
        value={statusFilter}
        onChange={handleMaterialStatusChange}
        options={materialStatusOptions}
      />
    </div>
  )
}
