import { STATISTICS_API_PATH } from '../../constants/globalConstants'
import { instance } from '../../utils/coreAPI'

export const getDashboardInfo = async () => {
  try {
    const response = await instance.get(STATISTICS_API_PATH)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
