import classNames from 'classnames'
import React from 'react'
import {
  ControlWrapperPrefixCls,
  CustomInputPrefix,
  InputPrefixCls,
} from './Input-constants'
import { InputProps } from './Input-types'
import './Input.sass'

export const Input: React.FC<InputProps> = (props) => {
  const {
    title,
    className,
    type,
    size = 'medium',
    required,
    placeholder,
    id,
    ...rest
  } = props
  const inputClassName = classNames(InputPrefixCls, CustomInputPrefix)
  const controlClassName = classNames(ControlWrapperPrefixCls, {
    [`${className}`]: className,
    [`${ControlWrapperPrefixCls}_size_sm`]: size === 'small',
    [`${ControlWrapperPrefixCls}_size_lg`]: size === 'large',
  })
  return (
    <div className={controlClassName}>
      <label htmlFor={id}>
        {required && <span>* </span>}
        {placeholder}
      </label>
      <input
        type={type}
        id={id}
        className={inputClassName}
        placeholder={''}
        {...rest}
      />
    </div>
  )
}
