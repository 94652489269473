import React from 'react'
import { ReactComponent as Logo } from '../../assets/img/logo.svg'
import './Navbar.sass'
import { ModeratorSelfType } from '../../utils/types'
import { useFetchProfileInfo } from '../ModeratorProfile/ModeratorProfile-hooks'
import classNames from 'classnames'
import { MenuModule } from '../MenuModule'

interface NavbarProps {
  className?: string
}

export const Navbar: React.FC<NavbarProps> = ({ className }) => {
  const navbarPrfx = 'navbar'
  const navbarCls = classNames(navbarPrfx, {
    [`${className}`]: className,
  })
  const { data: profileInfo } = useFetchProfileInfo() as {
    data: ModeratorSelfType
  }

  const userName = profileInfo?.login ? `, ${profileInfo?.login}` : ''

  return (
    <aside className={navbarCls}>
      <div className={`${navbarPrfx}__logo`}>
        <Logo />
      </div>
      <h3 className={`${navbarPrfx}__title`}>
        {`Добро пожаловать${userName}!`}{' '}
      </h3>
      <MenuModule className={`${navbarPrfx}__menu`} />
    </aside>
  )
}
