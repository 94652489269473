import React from 'react'
import { DashboardObjectType } from '../../GeneralPage-types'
import './InfoModuleMobile.sass'

interface InfoModuleMobileProps {
  totalData: DashboardObjectType
  monthlyData: DashboardObjectType
}

export const InfoModuleMobile: React.FC<InfoModuleMobileProps> = ({
  totalData,
  monthlyData,
}) => {
  return (
    <div className='info-module-mobile'>
      <div className='info-module-mobile-column'>
        <h3 className='info-module-mobile-column__title'>За месяц</h3>

        <div className='info-module-mobile-column__item'>
          <span className='info-module-mobile-column__item-title'>
            Отмодерировано
          </span>
          <span className='info-module-mobile-column__item-value'>
            {monthlyData.totalModerated}
          </span>
        </div>
        <div className='info-module-mobile-column__item'>
          <span className='info-module-mobile-column__item-title'>Принято</span>
          <span className='info-module-mobile-column__item-value'>
            {monthlyData.acceptModerated}
          </span>
        </div>
        <div className='info-module-mobile-column__item'>
          <span className='info-module-mobile-column__item-title'>
            Отклонено
          </span>
          <span className='info-module-mobile-column__item-value'>
            {monthlyData.deniedModerated}
          </span>
        </div>
      </div>

      <div className='info-module-mobile-column'>
        <h3 className='info-module-mobile-column__title'>За всё время</h3>

        <div className='info-module-mobile-column__item'>
          <span className='info-module-mobile-column__item-title'>
            Отмодерировано
          </span>
          <span className='info-module-mobile-column__item-value'>
            {totalData.totalModerated}
          </span>
        </div>
        <div className='info-module-mobile-column__item'>
          <span className='info-module-mobile-column__item-title'>Принято</span>
          <span className='info-module-mobile-column__item-value'>
            {totalData.acceptModerated}
          </span>
        </div>
        <div className='info-module-mobile-column__item'>
          <span className='info-module-mobile-column__item-title'>
            Отклонено
          </span>
          <span className='info-module-mobile-column__item-value'>
            {totalData.deniedModerated}
          </span>
        </div>
      </div>
    </div>
  )
}
