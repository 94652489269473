import { useQuery } from 'react-query'
import { getMaterialDetailsById } from '../api/getMaterialDetailsById'
import { MATERIALS_CACHE_PATH } from '../constants/globalConstants'

export const useMaterialById = (materialId: string) => {
  const query = useQuery(
    [MATERIALS_CACHE_PATH, materialId],
    async () => {
      const adminById = await getMaterialDetailsById(materialId)
      return adminById
    },
    {
      refetchOnMount: true,
    }
  )
  return query
}
