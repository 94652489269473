import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { SELF_CACHE_PATH } from '../../constants/globalConstants'
import { getSelfModerator } from './ModeratorProfile-api'

export const useFetchProfileInfo = () => {
  const query = useQuery(
    SELF_CACHE_PATH,
    async () => await getSelfModerator(),
    {
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось получить все данные')
      },
      retry: 1,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  )
  return query
}
