import React from 'react'
import { ModerationHistoryWindow } from '../../../../components/ModerationHistoryWindow'
import { ModerationsHistoryType } from '../../ModerationHistoryPage-types'

interface ModerationHistoryMobileProps {
  moderationHistoryList: ModerationsHistoryType
  handleRowClick: (id: number) => void
}

export const ModerationHistoryMobile: React.FC<
  ModerationHistoryMobileProps
> = ({ moderationHistoryList, handleRowClick }) => {
  if (!moderationHistoryList.length) {
    return <span>Отсутствует история модерации</span>
  }
  return (
    <div className='windows-wrapper'>
      <div className='windows-wrapper-windows'>
        {moderationHistoryList.map((moderationHistoryItem) => (
          <ModerationHistoryWindow
            className='windows-wrapper-windows__item'
            key={moderationHistoryItem.id}
            materialId={moderationHistoryItem.material.id}
            moderateEnd={moderationHistoryItem.moderateEnd}
            materialAuthor={moderationHistoryItem.material.contributor.name}
            status={moderationHistoryItem.status}
            onClick={() => handleRowClick(moderationHistoryItem.id)}
          />
        ))}
      </div>
    </div>
  )
}
