import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { DashboardObjectType } from '../../GeneralPage-types'
import './InfoModuleDesktop.sass'

interface InfoModuleDesktopProps {
  totalData: DashboardObjectType
  monthlyData: DashboardObjectType
}

export const InfoModuleDesktop: React.FC<InfoModuleDesktopProps> = ({
  totalData,
  monthlyData,
}) => {
  const data = [
    {
      name: 'Всего',
      monthly: monthlyData.totalModerated,
      total: totalData.totalModerated,
    },
    {
      name: 'Принято',
      monthly: monthlyData.acceptModerated,
      total: totalData.acceptModerated,
    },
    {
      name: 'Отклонено',
      monthly: monthlyData.deniedModerated,
      total: totalData.deniedModerated,
    },
  ]
  return (
    <div className='info-module'>
      <ResponsiveContainer
        className={'info-module__plot'}
        width={'99%'}
        height={'99%'}
      >
        <BarChart
          data={data}
          barSize={50}
          className={'info-module__barchart'}
          barGap={5}
          barCategoryGap={20}
          maxBarSize={10}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 0,
          }}
        >
          <XAxis
            dataKey='name'
            className={'info-module__axis_x'}
            tickLine={false}
            minTickGap={20}
          />
          <YAxis
            className={'info-module__axis_y'}
            tickLine={false}
            minTickGap={15}
          />
          <Tooltip
            animationDuration={200}
            wrapperClassName={'info-module__tooltip_wrapper'}
            labelClassName={'info-module__tooltip_label'}
            isAnimationActive={false}
            wrapperStyle={{ overflow: 'hidden' }}
          />
          <Legend
            className={'info-module__legend'}
            verticalAlign='top'
            height={50}
          />
          <Bar
            dataKey='total'
            legendType='circle'
            fill='#141e61'
            name='всего'
            className={'info-module__bar'}
          />
          <Bar
            dataKey='monthly'
            legendType='circle'
            fill='#408CFF'
            name='за последний месяц'
            className={'info-module__bar'}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
