import {
  MAIN_PATH,
  MODERATION_HISTORY_API_PATH,
} from '../../constants/globalConstants'

export const MODERATION_HISTORY_SIZE = 12

export const breadCrumbsData = [
  {
    id: 0,
    name: 'Кабинет модератора',
    path: MAIN_PATH,
  },
  {
    id: 1,
    name: 'История модерации',
    path: MODERATION_HISTORY_API_PATH,
  },
]
