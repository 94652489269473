import classNames from 'classnames'
import React from 'react'
import { IoExitOutline } from 'react-icons/io5'
import { NavbarItem } from '../../components/NavbarItem'
import {
  MAIN_PATH,
  MODERATION_HISTORY_PATH,
  MODERATION_PATH,
} from '../../constants/globalConstants'
import { useLogout } from '../../pages/AuthPage/modules/AuthModule-hooks'
import './MenuModule.sass'

interface MenuModuleProps {
  className?: string
  alignCenter?: boolean
}

export const MenuModule: React.FC<MenuModuleProps> = ({
  className,
  alignCenter = false,
}) => {
  const menuPrfx = 'application-menu'
  const menuCls = classNames(menuPrfx, {
    [`${className}`]: className,
  })

  const { mutate: logout } = useLogout() as {
    mutate: () => void
  }

  const handleExitClick = () => {
    logout()
  }

  return (
    <ul className={menuCls}>
      <NavbarItem
        text='Главная страница'
        pathTo={MAIN_PATH}
        alignCenter={alignCenter}
        icon={null}
        className={`${menuPrfx}__item`}
      />
      <NavbarItem
        text='Модерация'
        pathTo={MODERATION_PATH}
        alignCenter={alignCenter}
        icon={null}
        className={`${menuPrfx}__item`}
      />
      <NavbarItem
        text='История модерации'
        pathTo={MODERATION_HISTORY_PATH}
        alignCenter={alignCenter}
        className={`${menuPrfx}__item`}
        icon={null}
      />
      <NavbarItem
        text='Выйти'
        pathTo='/exit'
        icon={IoExitOutline}
        alignCenter={alignCenter}
        handleClick={handleExitClick}
        className={`${menuPrfx}__item`}
      />
    </ul>
  )
}
