import classNames from 'classnames'
import React from 'react'
import './Title.sass'

interface TitleProps {
  className?: string
  text?: string
}

export const Title: React.FC<TitleProps> = ({ className, text }) => {
  const titlePrfx = 'custom-title'
  const titleCls = classNames(titlePrfx, {
    [`${className}`]: className,
  })
  return <h2 className={titleCls}>{text}</h2>
}
