import { ROLE_MODERATOR } from '../constants/globalConstants'

export const isAuthenticated = () => {
  const permissions = localStorage.getItem('permissions')
  const token = localStorage.getItem('token')
  if (!permissions && !token) {
    return false
  }
  const isAuth = permissions === ROLE_MODERATOR
  if (isAuth) {
    return true
  } else {
    return false
  }
}
